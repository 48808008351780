import React, {useState} from 'react';
import './news.css'
import CustomNavbar1 from './Navbar1';
import Footer from './footer'
import { useMediaQuery } from "react-responsive";
import { database } from './firebase';
import { ref, push } from "firebase/database";
import emailjs from '@emailjs/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faLinkedin,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import { Modal, Button } from "react-bootstrap";
import { faEnvelope, faPhone, faCheck } from '@fortawesome/free-solid-svg-icons';

const LearnWithMe = () => {

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    const [showDoneReport, setShowDoneshowDoneReport] = useState(false);

    const handleCloseShowDoneReport = () => setShowDoneshowDoneReport(false);
    const handleShowDoneshowDoneReport = () => setShowDoneshowDoneReport(true);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (name && validateEmail(email) && message) {
            const dataToStore = {
                name: name,
                email: email,
                message: message
            };

            const dataRef = ref(database, "message-contact");
            push(dataRef, dataToStore)
            .then(() => {
                console.log('Data has been stored in Firebase.');

                // Send email using EmailJS with the data from Firebase
                emailjs.send('service_apcd55a', 'template_jejzrnm', {
                    to_email: 'tarakram2003@gmail.com',
                    subject: 'Website message',
                    name: dataToStore.name,
                    email: dataToStore.email,
                    message: dataToStore.message,
                }, 'Pjh05O3PPHpKASlMj')
                .then((result) => {
                    console.log('Email sent successfully:', result.text);
                })
                .catch((error) => {
                    console.error('Error sending email:', error.text);
                });

                // Clear the input fields after submission
                setName('');
                setEmail('');
                setMessage('');
                handleShowDoneshowDoneReport()
            })
            .catch((error) => {
                console.error('Error storing data in Firebase:', error);
            });
        } else {
            alert('Please fill in all the required fields properly');
        }
    };

    const isMobile = useMediaQuery({ query: '(max-width: 800px)' });

  return (
    <div style={{fontFamily: 'Maven Pro', color: '#fff', backgroundColor: '#490648'}}>    
      <CustomNavbar1 />
        <div style={{paddingTop: '5em'}}>
          <h2 style={{textAlign: 'center', fontSize: '2.8em', fontWeight: '800'}}>Contact Now</h2>
          <div>
          <div style={{ padding: '3em' }}>
                <h2 style={{ fontSize: '2.2em', fontWeight: '1000', margin: '0.5em 0 1em' }}>Send a Message</h2>
                <div className='ContactForm'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <div className='contactForm'>
                                    <form id='contact-form' onSubmit={handleSubmit} noValidate>
                                        {/* Row 1 of form */}
                                        <div className='row formRow' style={{ display: `${isMobile ? 'inline' : 'flex'}` }}>
                                            <div className='col-6' style={{ width: `${isMobile ? '100%' : '50%'}`, marginBottom: '1em' }}>
                                                <input
                                                    type='text'
                                                    name='name'
                                                    className='form-control-1 formInput'
                                                    placeholder='Name'
                                                    value={name}
                                                    onChange={handleNameChange}
                                                ></input>
                                            </div>
                                            <div className='col-6' style={{ width: `${isMobile ? '100%' : '50%'}` }}>
                                                <input
                                                    type='email'
                                                    name='email'
                                                    className='form-control-1 formInput'
                                                    placeholder='Email address'
                                                    value={email}
                                                    onChange={handleEmailChange}
                                                ></input>
                                            </div>
                                        </div>
                                        {/* Row 3 of form */}
                                        <div className='row formRow'>
                                            <div className='col'>
                                                <textarea
                                                    rows={4}
                                                    name='message'
                                                    className='form-control-1 formInput'
                                                    placeholder='Message'
                                                    value={message}
                                                    onChange={handleMessageChange}
                                                ></textarea>
                                            </div>
                                        </div>
                                        <button className='contact-page-button' type='submit'>
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
          </div>
        </div>
      <Footer />
      <Modal show={showDoneReport} onHide={handleCloseShowDoneReport}>
              <Modal.Body>
                <Modal.Title style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                  <FontAwesomeIcon icon={faCheck} style={{padding: '0.5em', fontSize: '2.3em', color: '#490648', fontWeight: '1000'}}/>
                </Modal.Title>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', padding: '0.3em 1em 0' }}>
                  <p>Thank you for your submission! I will review your submission and get back to you within 24 hours.</p>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', margin: '1em 0'}}>
                  <Button style={{backgroundColor: '#490648', border: 'none'}} onClick={handleCloseShowDoneReport}>
                    Close
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
    </div>
  );
};

export default LearnWithMe;