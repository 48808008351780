import React from "react";
import CustomNavbar1 from "./Navbar1";
import './about.css'
import Footer from "./footer";
import AboutImage from './images/AboutImage.JPG'
import TanaySign from './images/Tanay-Sign.png'

function About () {

    const currentImageIndex = 0;

    return (
        <div>
            <CustomNavbar1 />
            <div style={{display: 'flex'}}>
                <div className="background-about-1">
                    <div style={{justifyContent: 'center', margin: 'auto', display: 'flex'}}>
                        <img src={TanaySign} alt="" style={{width: '75%', margin: '9em 0 5em'}}/>
                    </div>
                </div>
                <div className="background-about-2">
                    <img src={AboutImage} alt="" className="background-image-about"/>
                </div>
            </div>
            <div className="background-about-3">
                <div style={{justifyContent: 'center', margin: 'auto', display: 'flex'}}>
                    <img src={TanaySign} alt="" style={{width: '56%', margin: '0 0 0'}}/>
                </div>
            </div>
            <div style={{backgroundColor: '#490648', paddingTop: '7em'}}>
                <div className="about-main-div">
                    <h1 className="ten-h1-1">About Me</h1>
                    <p className="about-div-text">Tanay Sudhan Rege, a virtuoso in the classical music domain, has been deeply engaged with the art of Tabla for over seventeen years. His initial tutelage was under the guidance of Guru Shri. Praveen Karkare at Sharda Sangeet Vidyalaya, and he has been honing his craft for the last ten years under the mentorship of Pt. Yogesh Samsi, immersing himself in the Punjab Gharana's rich traditions. His academic foundation is as robust as his musical journey, holding a Bachelor's in Commerce alongside a Master's in Music from the School of Performing Arts, Bharati Vidyapeeth University, Pune. He is also a Visharad in Tabla, underlining his expertise and commitment to his craft.</p>
                </div>
                <div className="about-main-div">
                    <h1 className="ten-h1-1">Achievements</h1>
                    <p className="about-div-text">A recipient of multiple scholarships, including those from the Central Government of India, Citi NCPA, and the Ministry of Culture, Tanay's talent has been recognized and nurtured through prestigious awards such as the Hridayesh Award, Swar-Naad Puraskar, AbhiRatna Puraskar, and the Pt. Paluskar Award. His performances on national and international stages, alongside maestros and in celebrated festivals, underscore his versatility and excellence in Tabla.</p>
                </div>
                <div className="about-main-div" style={{paddingBottom: '8em'}}>
                    <h1 className="ten-h1-1">My Mentors</h1>
                    <ul>
                        <li className="about-div-text"><b>Guru Shri. Praveen Karkare:</b> Introduction to his early teachings and influence on Tanay's foundational skills.</li>
                        <li className="about-div-text"><b>Pt. Yogesh Samsi:</b> Detailing the ongoing journey of learning and mastery under his guidance, reflecting the depth of Punjab Gharana's influence on Tanay's style.</li>
                    </ul>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default About