import React, { useState } from "react";
import CustomNavbar1 from "./Navbar1";
import './Home.css';
import Footer from './footer';

function BioData() {
    const [showEnglish, setShowEnglish] = useState(true);

    const toggleLanguage = () => {
        setShowEnglish(!showEnglish);
    };

    return (
        <div>
            <CustomNavbar1 />
            <button 
                onClick={toggleLanguage} 
                className="translate-button"
            >
                {showEnglish ? 'मराठीत वाचा' : 'Read in English'}
            </button>
            <div className="ten-bio">
                {showEnglish ? (
                    <div className="english">
                        <p style={{color: '#fff', opacity: '0.4'}}>Tanay Sudhan Rege</p>
                        <p style={{color: '#fff', opacity: '0.4', marginTop: '-1em', lineHeight: '1em'}}>Tabla Virtuoso | Cross-Cultural Collaborator | Educator</p>
                        <p style={{color: '#fff'}}>Tanay Sudhan Rege is a distinguished Tabla artist celebrated for his mastery in Indian classical music and his ability to transcend cultural boundaries. With over 17 years of rigorous training, Tanay began his journey under Guru Shri Praveen Karkare at Sharda Sangeet Vidyalaya and continues to hone his craft under Pt. Yogesh Samsi of the Punjab Gharana. A B-High grade artist recognized by All India Radio, Tanay’s artistry embodies the perfect balance of tradition and innovation.</p>
                        <p style={{color: '#fff'}}>A graduate of Bharati Vidyapeeth University, Pune, with a Master’s in Music, and a Visharad in Tabla, Tanay has received several prestigious scholarships, including those from the Central Government of India (CCRT), Citi NCPA (Support to Guru Scheme), and the Ministry of Culture, acknowledging his dedication to the art form.</p>
                        <p style={{color: '#fff'}}>Tanay’s career is adorned with performances on some of the world’s most celebrated stages. Internationally, he has performed at the La Biennale Art Festival in Venice, Italy; the Esplanade Concert Hall in Singapore; and the Ganga Cavery Festival in Doha, Qatar. Among his most prestigious performances was at the G20 Summit, attended by India’s Honourable Prime Minister, Shri Narendra Modi, and Nepal’s Prime Minister, Pushpa Kamal Dahal Prachanda.</p>
                        <p style={{color: '#fff'}}>In India, he has enthralled audiences at renowned festivals such as the Shri Baba Harivallabh Sangeet Samaroh (Jalandhar), Saptak Festival (Ahmedabad), Hridayesh Arts Festival (Mumbai), and the Tana Riri Mohotsav (Vadnagar).</p>
                        <p style={{color: '#fff'}}>Tanay has collaborated with some of the finest maestros of Indian classical music, including Vidushi Ashwini Bhide Deshpande, Pt. Ajay Pohankar, Pt. Raghunandan Panshikar, Maestro Purbayan Chatterjee, Pt. Jayateerth Mevundi, Dr. Sangeeta Shankar, Shri Rahul Deshpande, and Shri Mahesh Kale. His versatility also shines in the commercial realm, with contributions to Bollywood hits like “Rocky Aur Rani Ki Prem Kahani”, the Cannes-nominated Marathi film “Ticha Shahar Hona”, and the international short film “The First Film”, filmed all over the world.</p>
                        <p style={{color: '#fff'}}>Tanay’s passion for cross-cultural collaboration has led him to work extensively with fusion bands and global artists. He collaborates with Flamenco dance artist Kunal Om, blending the intricate rhythms of Tabla with Flamenco’s fiery expressions. His musical journey bridges Indian classical traditions with contemporary world music, making his performances a celebration of diversity.</p>
                        <p style={{color: '#fff'}}>Additionally, Tanay has partnered with composers like Kaushal Inamdar and Nilesh Moharir, as well as Marathi music stalwarts like Savaniee Ravindra and Neha Rajpal, and Bollywood singers like Mame Khan and Jasraj Joshi. His television and media presence includes interviews and performances on Zee Yuva Channel, Saam TV, DD Bharati, and IBN Lokmat.</p>
                        <p style={{color: '#fff'}}>Beyond the stage, Tanay is a dedicated educator, conducting lecture-demonstrations at Ecole Mondiale World School and St. Xavier’s College, Mumbai. He mentors students across India and abroad, passionately fostering the next generation of musicians.</p>
                        <p style={{color: '#fff'}}>With an unrelenting dedication to Tabla and a vision that embraces global artistic dialogue, Tanay continues to inspire audiences worldwide, bridging tradition with modernity.</p>
                    </div>
                ) : (
                    <div className="marathi">
                        <p style={{color: '#fff', opacity: '0.4'}}>तनय सुधन रेगे</p>
                        <p style={{color: '#fff', opacity: '0.4', marginTop: '-1em', lineHeight: '1em'}}>तबला वादक | सांस्कृतिक सहयोगकर्ता | संगीत शिक्षक</p>
                        <p style={{color: '#fff'}}>तनय सुधन रेगे हे एक प्रख्यात तबला वादक असून, भारतीय शास्त्रीय संगीतातील त्यांचे सखोल अध्ययन आणि सांस्कृतिक सीमारेषा ओलांडण्याची क्षमता यामुळे ते विशेष ओळखले जातात. तबल्याचा अभ्यास १७ वर्षांहून अधिक काळ त्यांनी केला आहे. त्यांनी आपल्या संगीत शिक्षणाची सुरुवात शारदा संगीत विद्यालयातील गुरु श्री. प्रविण करकरे यांच्याकडे केली आणि सध्या ते पंजाब घराण्याचे पं. योगेश समसी यांच्या मार्गदर्शनाखाली आपली साधना सुरू ठेवत आहेत.</p>
                        <p style={{color: '#fff'}}>All India Radio चे  ‘B-High' दर्जाचे कलाकार म्हणून तनय यांचे संगीत प्रगल्भतेसाठी ओळखले जाते. त्यांनी भारती विद्यापीठ, पुणे येथून संगीत विषयात पदव्युत्तर पदवी घेतली असून ते तबल्याचे विशारद आहेत. त्यांना  CCRT, City NCPA (Support to Guru Scheme), आणि सांस्कृतिक मंत्रालय यांच्यासारख्या प्रतिष्ठित शिष्यवृत्ती प्राप्त झाल्या आहेत, ज्यामुळे त्यांचे संगीत क्षेत्रातील समर्पण अधोरेखित होते.</p>
                        <p style={{color: '#fff'}}>तनय यांची कारकीर्द भारतातील आणि जगभरातील ख्यातनाम व्यासपीठांवरील अप्रतिम सादरीकरणांनी समृद्ध आहे. आंतरराष्ट्रीय स्तरावर त्यांनी ला बिएनाले आर्ट फेस्टिव्हल (व्हेनिस, इटली), एस्प्लानेड (सिंगापूर) आणि गंगा कावेरी फेस्टिव्हल (दोहा, कतार) येथे आपली कला सादर केली आहे. तसेच, त्यांनी G-20 शिखर परिषदेमध्ये भारताचे पंतप्रधान श्री. नरेंद्र मोदी आणि नेपाळचे पंतप्रधान पुष्पकमल दहाल प्रचंड यांच्या सन्मुख सादरीकरण करण्याचा गौरव प्राप्त केला आहे.</p>
                        <p style={{color: '#fff'}}>भारतातही त्यांनी श्री बाबा हरिवल्लभ संगीत समारोह (जालंधर), सप्तक महोत्सव (अहमदाबाद), हृदयेश आर्ट्स फेस्टिव्हल (मुंबई) आणि ताना-रीरी महोत्सव (वडनगर) यांसारख्या नामांकित उत्सवांमध्ये आपल्या तबल्याची गोडी प्रेक्षकांना दिली आहे.</p>
                        <p style={{color: '#fff'}}>तनय यांनी विदुषी अश्विनी भिडे देशपांडे, पं. अजय पोहनकर, पं. रघुनंदन पणशीकर, पं.पूर्बायन चॅटर्जी, पं. रवि चारी, डॉ. संगीता शंकर, श्री राहुल देशपांडे, आणि श्री महेश काळे यांसारख्या दिग्गज कलाकारांसोबत सहवादन केले आहे. त्यांचे व्यावसायिक कामही लक्षणीय आहे; त्यांनी “रॉकी और रानी की प्रेम कहानी” या हिंदी चित्रपटासाठी तबलावादन केले असून, “तिचं शहर होणार” या कान्स फिल्म फेस्टिव्हलमध्ये नामांकित मराठी चित्रपटासाठी आपले योगदान दिले आहे.</p>
                        <p style={{color: '#fff'}}>तनय सांस्कृतिक सहकार्याला विशेष महत्त्व देतात. त्यांनी अनेक फ्युजन बँड्स आणि फ्लॅमेंको नृत्य कलाकार कुणाल ओम यांच्यासोबत काम केले आहे. तबल्याच्या जटिल लयींना फ्लॅमेंकोच्या तीव्र भावनांशी जोडत त्यांनी संगीतात एक अद्वितीय संगम साकारला आहे.</p>
                        <p style={{color: '#fff'}}>तसेच, त्यांनी कौशल इनामदार आणि नीलेश मोहरीर यांसारख्या संगीत दिग्दर्शकांसोबत आणि सावनी रविंद्र, नेहा राजपाल, तसेच मामे खान यांसारख्या लोकप्रिय गायक-गायिकांसोबत काम केले आहे. त्यांचा संगीतप्रवास विविध टेलिव्हिजन वाहिन्यांवरही दिसून येतो, जसे की झी युवा, साम टीव्ही, दूरदर्शन सह्याद्री, आणि आयबीएन लोकमत.</p>
                        <p style={{color: '#fff'}}>संगीत सादरीकरणाबरोबरच तनय हे शिक्षण क्षेत्रातही सक्रिय आहेत. त्यांनी सेंट झेवियर्स कॉलेज, मुंबई आणि इकोल मोंडियाल वर्ल्ड स्कूल, जुहू येथे व्याख्यान व प्रात्यक्षिक सत्र घेतले आहेत. भारतात आणि परदेशात पसरलेल्या त्यांच्या शिष्यांमार्फत ते भारतीय संगीताची परंपरा पुढे नेत आहेत.</p>
                        <p style={{color: '#fff'}}>तबल्याच्या कलात्मकतेसाठी तनय सुधन रेगे यांचे योगदान भारतीय शास्त्रीय संगीताला एका जागतिक पातळीवर पोहोचवते, ज्यामध्ये परंपरेचा पाया आणि आधुनिकतेचा साज यांचा अनोखा संगम आहे.</p>
                    </div>
                )}
                <div style={{marginTop: '2em'}}>         
                    <a className="download-bio-button" target="_none" href="https://drive.google.com/drive/folders/1zBwcDx9MZoqw8v0v3Ikt_aqqtWc7UlT7">Download Tanay’s Pictures for Concert Posters / Passes</a>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default BioData;
