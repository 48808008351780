import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import logo from './images/Tanay.png'; // Import your logo
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import './footer.css';
import Form from 'react-bootstrap/Form';
import {
  faFacebook,
  faInstagram,
  faYoutube, 
  faSpotify
} from '@fortawesome/free-brands-svg-icons';

const Footer = () => {

  const currentYear = new Date().getFullYear();

  const footerStyle = {
    padding: '3em 3em 1em',
    backgroundColor: '#fff', // Change background color based on path
    fontFamily: 'Maven Pro'
  };

  return (
    <footer style={footerStyle}>
      <Container>
        <Row>
          <Col xs={12} md={3} style={{marginRight: '2.2em'}}>
            <img src={logo} alt="Logo" className='logo-footer-company' />
            
          </Col>
          <Col xs={12} md={2} style={{marginRight: '3.7em'}}>
            <h4 style={{color:'#490648', fontWeight: '800', paddingBottom: '0.3em'}}>Website Links</h4>
            <ul className="list-unstyled">
              <li><a href="/about" className='footer-link-content'>About</a></li>
              <li><a href="/bio-data" className='footer-link-content'>Bio Data</a></li>
              <li><a href="/contact" className='footer-link-content'>Contact Me</a></li>
              <li><a href="/news" className='footer-link-content'>News</a></li>
              <li><a href="/learn-with-me" className='footer-link-content'>Learn With Me</a></li>
            </ul>
          </Col>
          <Col xs={12} md={3} style={{marginRight: '0em'}}>
            <h4 style={{color:'#490648', fontWeight: '800', paddingBottom: '0.3em'}}>Find Me Here</h4>
            <ul className="list-unstyled">
              <li style={{marginTop: '0.3em'}}><a href='mailto:office@tanayrege.com' className='footer-link-content'>office@tanayrege.com</a></li>
              <div>
                <a href="https://www.instagram.com/tanay_tabla?igsh=MW1nNzloNGh5ZXRpOQ==" target='_blank'><FontAwesomeIcon icon={faInstagram} className='social-link-1'/></a>
                <a href="https://m.facebook.com/tanay.rege?mibextid=LQQJ4d" target='_blank'><FontAwesomeIcon icon={faFacebook} className='social-link-1'/></a>
                <a href="https://youtube.com/@TanayRegeOfficial?si=HiZejbt5f5YCdcXa" target='_blank'><FontAwesomeIcon icon={faYoutube} className='social-link-1'/></a>
                <a href="https://open.spotify.com/artist/3WsijL0fICz58NPlhB0EIB?si=2GqsoctLTi-MUl10ff9qrg" target='_blank'><FontAwesomeIcon icon={faSpotify} className='social-link-1'/></a>
              </div>
            </ul>
          </Col>
          <Col xs={12} md={3}>
            <h4 style={{color:'#490648', fontWeight: '800', paddingBottom: '0.3em'}}>Join the Mailing List</h4>
            <p style={{color: '#490648'}}>Receive updates on schedule, content & upcoming events.</p>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <div style={{display: 'flex'}}>
                  <Form.Control type="email" placeholder="Your E-Mail ID" style={{opacity: '0.8', marginRight:'0.2em', border: '0.07em solid #490648'}}/>
                  <Button variant="primary" className="ml-2" style={{backgroundColor: '#490648', border: '0px'}}><FontAwesomeIcon icon={faPaperPlane} /></Button>
                </div>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
      <hr />
      <div>
        <p className='footer-copy'>&copy; {currentYear} Tanay Rege | All Rights Reserved.</p>
        <p className='footer-copy-1'>Designed and Developed by <a href="http://www.musomint.in" target='_blank' style={{color: '#490648', textDecoration: 'none', fontWeight: '700'}}>Musomint™</a> Web Technologies</p>
        <p className='footer-copy-2'>Powered by <a href="http://www.musomint.in" target='_blank' style={{color: '#490648', textDecoration: 'none', fontWeight: '700'}}>Musomint™</a> Broadcasting & Media</p>
      </div>
    </footer>
  );
};

export default Footer;

/*
<div className="social-media-links">
                <a href="https://www.facebook.com/fliptotriptravel/" target='_blank'>
                    <FontAwesomeIcon icon={faFacebook} className='social-link-content'/>
                </a>
                <a href="https://www.youtube.com/@fliptotrip" target='_blank'>
                    <FontAwesomeIcon icon={faYoutube} className='social-link-content'/>
                </a>
                <a href="https://www.instagram.com/flip2trip/" target='_blank'>
                    <FontAwesomeIcon icon={faInstagram} className='social-link-content'/>
                </a>
                <a href="https://www.linkedin.com/company/fliptotrip/" target='_blank'>
                    <FontAwesomeIcon icon={faLinkedin} className='social-link-content'/>
                </a>
                <a href="https://api.whatsapp.com/send/?phone=919740096300&text&type=phone_number&app_absent=0" target="_blank">
                    <FontAwesomeIcon icon={faWhatsapp} className='social-link-content'/>
                </a>
            </div>
*/