import React from "react";
import CustomNavbar1 from "./Navbar1";
import backgroundImage1 from './images/Background1.jpg'
import backgroundMobile from './images/BackgroundMobile.jpg'
import About1Image from './images/About1Image.JPG'
import './Home.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import Footer from './footer'
import { useMediaQuery } from 'react-responsive';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './Home1.css'
import Gallery1 from './images/Gallery1.jpg'
import Gallery2 from './images/Gallery2.jpg'
import Gallery3 from './images/Gallery3.jpg'
import Gallery4 from './images/Gallery4.jpg'
import Gallery5 from './images/Gallery5.jpg'
import Gallery6 from './images/Gallery6.jpg'

function Home () {

    const isMobile = useMediaQuery({ query: '(max-width: 900px)' });

    return (
        <div>
            <CustomNavbar1 />
            <div>
                <div className="background-container">
                <div className="background-image" style={{backgroundImage: `url(${!isMobile ? backgroundImage1 : backgroundMobile})`}}>
                    <div className="text-container">
                        <div className="background-button-div">
                            <button className="Upcoming-button">Upcoming Events →</button>
                            <a href="/contact" style={{padding: '0', textDecoration: 'none', marginTop: '0.5em'}}><button className="Reach-button">Reach Out <FontAwesomeIcon icon={faComments} /></button></a>
                        </div>
                        <div style={{width: '30%'}}></div>
                    </div>
                </div>
                </div>
            </div>
            <div className="ten">
                <div className="about-home-div">
                    <div className="about-home-div-1">
                        <p className="intro-tagline">From Tanay's Riyaz Room</p>
                        <img src={About1Image} alt="" className="about-image-div"/>
                    </div>
                    <div className="about-home-div-2">
                        <p className="content-one">Growing up with no musical background in my family, my journey into the world of Indian Classical Music, particularly into the realm of tabla, has been nothing short of a thrilling adventure marked by perseverance, dedication, and countless hours of practice, often referred to as "riyaz" in our tradition. My passion for the tabla led me to the esteemed tutelage of Shri Praveen Karkare and later, the legendary Pt. Yogesh Samsi, under whose guidance I honed my craft and deepened my understanding of this intricate art form.</p>
                        <p className="content-one">Despite the lack of a musical lineage in my family, the rhythmic beats of the tabla always felt like a call to a path that was destined for me. This path was not easy. It was paved with challenges and obstacles, but also with moments of exhilaration and profound learning. The journey required not just talent, but an unwavering commitment to practice, an insatiable curiosity to explore the depths of classical music, and a resilience to overcome every hurdle that came my way.</p>
                        <p className="content-one">Over the years, I have been fortunate to perform tabla solos on prestigious stages and to accompany senior and renowned artists of Indian Classical Music, each performance being a testament to the years of riyaz, struggle, and the relentless pursuit of excellence. These experiences have not only been opportunities to showcase my skill but have also been invaluable learning experiences, allowing me to grow as a musician and as an individual.</p>
                        <p className="content-one">From my riyaz room, the sounds of my tabla resonate, carrying with them stories of struggle, perseverance, and the joy of music. It is here that I connect with the soul of my art, continuing to explore, learn, and share the beauty of Indian Classical Music with the world. My journey is a reminder that with passion and hard work, it is possible to overcome any obstacle and achieve one's dreams, even in fields where you have no background or prior footing.</p>
                    </div>
                </div>
                <h1 className="ten-h1">My achievements</h1>
                <div className="achievement-home-div">
                    <p className="content-one">A recipient of multiple scholarships, including those from the Central Government of India, Citi NCPA, and the Ministry of Culture, Tanay's talent has been recognized and nurtured through prestigious awards such as the Hridayesh Award, Swar-Naad Puraskar, AbhiRatna Puraskar, and the Pt. Paluskar Award. His performances on national and international stages, alongside maestros and in celebrated festivals, underscore his versatility and excellence in Tabla.</p>
                </div>
                <h1 className="ten-h1">Gallery</h1>
                <div className="ten-gallery-div">
                    <Container>
                        <Row style={{gap: '0'}}>
                            <Col>
                                <div className='image-container-for-album'>
                                    <img className='image-for-album' src={Gallery1} alt="" />
                                    <div class="hover-text-for-album" style={{fontWeight: 'bold', fontSize: '0.7em', lineHeight: '1em'}}>Receiving the Pt V.D Paluskar award from Padma Vibhushan Pt Jasraj ji and Dr Vikas Kashalkar ji</div>
                                </div>
                            </Col>
                            <Col>
                                <div className='image-container-for-album'>
                                    <img className='image-for-album' src={Gallery2} alt="" />
                                    <div class="hover-text-for-album" style={{fontWeight: 'bold', fontSize: '0.7em', lineHeight: '1em'}}>Moments captured while accompanying Pt Ajay Pohankar ji</div>
                                </div>
                            </Col>
                            <Col>
                                <div className='image-container-for-album'>
                                    <img className='image-for-album' src={Gallery3} alt="" />
                                    <div class="hover-text-for-album" style={{fontWeight: 'bold', fontSize: '0.7em', lineHeight: '1em'}}>Moments while accompanying Guruji Pt Yogesh Samsi ji at a lec dem at the NCPA</div>
                                </div>
                            </Col>
                            <Col>
                                <div className='image-container-for-album'>
                                    <img className='image-for-album' src={Gallery4} alt="" />
                                    
                                </div>
                            </Col>
                            <Col>
                                <div className='image-container-for-album'>
                                    <img className='image-for-album' src={Gallery5} alt="" />
                                    
                                </div>
                            </Col>
                            <Col>
                                <div className='image-container-for-album'>
                                    <img className='image-for-album' src={Gallery6} alt="" />
                                    
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Home